/*
 * @Author: fangjun
 * @Date: 2022-12-06 13:56:35
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-07-11 09:08:57
 * @FilePath: \src\api\mapMgt\mapQuery.js
 */

import request from '@/utils/axiosReq'

/**
 * @description: 创建客户区域地图
 * @param {*} query
 * @return {*}
 */
export function apiAddCustomAreaMap(query, file, handleProgress) {
  return request({
    url: '/map/customArea/map/add',
    onUploadProgress: (event) => {
      handleProgress(event, file)
    },
    timeout: 10 * 60 * 1000,
    method: 'post',
    data: query
  })
}

/**
 * @description: 获取客户区域地图列表
 * @param {*} data
 * @return {*}
 */
export function apiGetCustomAreaMapList(data) {
  return request({
    url: '/map/customArea/map/list',
    method: 'post',
    data
  })
}
/**
 * @description: 编辑客户区域地图
 * @param {*}
 * @return {*}
 */
export function apiUpdateCustomAreaMap(data) {
  return request({
    url: '/map/customArea/map/update',
    method: 'post',
    data
  })
}
/**
 * @description: 获取客户区域地图下发记录
 * @param {*} query
 * @return {*}
 */
export function apiGetDispenseList(data) {
  return request({
    url: '/map/customArea/map/getDispenselist',
    method: 'post',
    data: data
  })
}

/**
 * @description: 客户区域地图重新下发
 * @param {*} data
 * @return {*}
 */
export function apiReissue(data) {
  return request({
    url: '/map/customArea/map/issue',
    method: 'get',
    data
  })
}
/**
 * @description: 客户区域地图手动下发
 * @param {*} data
 * @return {*}
 */
export function apiMapIssue(data) {
  return request({
    url: '/map/customArea/map/mapIssue',
    method: 'post',
    data
  })
}
/**
 * @description: 地图文件重新下发
 * @param {*} data
 * @return {*}
 */
export function apiRepeatIssueMapFile(data) {
  return request({
    url: '/map/mapLinksRecord/againIssue',
    method: 'post',
    data
  })
}

/**
 * @description: 获取机器人基础类型列表
 * @param {*}
 * @return {*}
 */
export function apiGetAmrBasicTypes(data) {
  return request({
    url: '/console/basic/amrbasictype/selectList',
    method: 'get',
    data
  })
}
/**
 * @description: 根据客户区域id和类型获取机器人列表
 * @param {*}
 * @return {*}
 */
export function apiGetAmrListByCustomAreaIdAndType(data) {
  return request({
    url: '/console/customArea/amr/getAmrListByCustomAreaAndType',
    method: 'get',
    data
  })
}

/**
 * @description: 获取机器人型号
 * @param {*}
 * @return {*}
 */
export function apiGetAmrTypes(data) {
  return request({
    url: '/console/amr/category/list',
    method: 'get',
    data
  })
}

export function apiGetMapInfo(data) {
  return request({
    url: '/map/customArea/map/getById',
    method: 'get',
    data
  })
}
/**
 * @description: 获取地图的比例尺
 * @param {*} data
 * @return {*}
 */
export function apiGetMapResolution(data) {
  return request({
    url: 'map/customArea/map/getMapYamlEntity',
    method: 'get',
    data
  })
}
